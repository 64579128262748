.AppTest {
  height: 100vh;
  width: 100vw;
  // background-image: url("../../public/bg1.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-attachment: fixed;
  // animation: bgBodyShow 3s;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url("../../public/bg1.jpg");
  //   background-size: cover;
  //   background-position: center;
  //   opacity: 0.5;
  //   animation: glitch2 2s linear infinite alternate;
  // }
}

// @keyframes glitch2 {
//   0% {
//     background-position: 0 0;
//   }
//   10% {
//     background-position: 5px 0;
//   }
//   20% {
//     background-position: 10px 0;
//   }
//   30% {
//     background-position: 15px 0;
//   }
//   40% {
//     background-position: 20px 0;
//   }
//   50% {
//     background-position: 25px 0;
//   }
//   60% {
//     background-position: 30px 0;
//   }
//   70% {
//     background-position: 35px 0;
//   }
//   80% {
//     background-position: 40px 0;
//   }
//   81% {
//     background-position: 0 0;
//   }
//   100% {
//     background-position: 0 0;
//   }
// }

.MainSlider {
  width: 100%;
  height: 100%;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.SpaceHome {
  width: 100vw;
  height: 1px;
}
body {
  overflow: hidden;
  // background-image: url("https://i.postimg.cc/bvmnZjT9/bg4.webp");
  // background-image: url("../../public/bg1.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-attachment: fixed;
  // animation: bgBodyShow 3s;
}

@keyframes bgBodyShow {
  0% {
    background-size: 0px;
  }
  100% {
    background-size: 0px;
  }
}
