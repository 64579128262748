.Cinema,
.TVShow,
.Documental,
.Progress,
.Distribution,
.Partners {
  @apply flex justify-center items-center flex-col;
  width: 80vw;
  height: calc(100vh - 90px - 150px);
  overflow: hidden;
  z-index: 20;

  .Title {
    @apply uppercase;
    font-size: 30px;
    font-weight: 500;
    font-family: "Roboto Condensed", sans-serif;
  }
}

.Carousel {
  @apply flex justify-center items-center;
  width: 1000px !important;
  height: 65vh !important;
  .swiper-wrapper {
    height: 752px;
    .swiper-slide {
      @apply flex flex-col text-start;
      border-radius: 2px;
      cursor: pointer;
      transition: 0.3s all;
      width: 450px !important;
      height: 752px !important;

      .overlay {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .Poster_Image {
        height: 676px;
        width: 450px !important;
        border-radius: 2px;
        filter: brightness(50%);
      }
      .SlideText {
        width: 100%;
        text-align: start;
        opacity: 0;
      }
      h5 {
        text-align: start;
        padding: 5px;
        width: 100%;
      }
    }
    .swiper-slide-active {
      transition: 0.3s all !important;
      &:active {
        transform: translateY(15px) !important;
      }
      .Poster_Image {
        filter: brightness(100%);
      }
      .overlay {
        display: none;
      }
      .SlideText {
        opacity: 1;
      }
    }
  }
}

.Title {
  margin-bottom: 50px;
}

@media only screen and (min-width: 320px) and (max-width: 1300px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 650px !important;
    .swiper-wrapper {
      .swiper-slide {
        width: 400px !important;
        height: 680px !important;
        .Poster_Image {
          height: 600px;
          width: 400px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 850px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 500px !important;
    .swiper-wrapper {
      height: 580px !important;
      .swiper-slide {
        width: 350px !important;
        height: 575px !important;
        .Poster_Image {
          height: 500px;
          width: 350px !important;
        }
      }
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 1120px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 1000px !important;
    height: 1000px !important;
    .swiper-wrapper {
      @apply flex items-center;
      .swiper-slide {
        width: 400px !important;
        height: 680px !important;
        .Poster_Image {
          height: 600px;
          width: 400px !important;
        }
      }
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 1000px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 800px !important;
    height: 800px !important;
    .swiper-wrapper {
      // height: 580px !important;
      .swiper-slide {
        width: 350px !important;
        height: 575px !important;
        .Poster_Image {
          height: 500px;
          width: 350px !important;
        }
      }
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 900px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 700px !important;
    height: 650px !important;
    .swiper-wrapper {
      height: 520px !important;
      .swiper-slide {
        width: 300px !important;
        height: 520px !important;
        .Poster_Image {
          height: 450px;
          width: 300px !important;
        }
        .SlideText {
          h5 {
            padding: 2px;
          }
        }
      }
    }
  }
  .Title {
    margin-bottom: 25px;
  }
}
@media only screen and (min-height: 320px) and (max-height: 900px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 700px !important;
    height: 650px !important;
    .swiper-wrapper {
      height: 520px !important;
      .swiper-slide {
        width: 300px !important;
        height: 520px !important;
        .Poster_Image {
          height: 450px;
          width: 300px !important;
        }
        .SlideText {
          h5 {
            padding: 2px;
          }
        }
      }
    }
  }
  .Title {
    margin-bottom: 25px;
  }
}
@media only screen and (min-height: 320px) and (max-height: 800px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 600px !important;
    .swiper-wrapper {
      height: 450px !important;
      .swiper-slide {
        width: 260px !important;
        height: 420px !important;
        .Poster_Image {
          height: 400px;
          width: 260px !important;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
  .Title {
    font-size: 25px !important;
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-height: 320px) and (max-height: 750px),
  only screen and (min-width: 320px) and (max-width: 1300px) {
  .Cinema,
  .TVShow,
  .Documental,
  .Progress,
  .Distribution,
  .Partners {
    height: calc(100vh - 90px - 90px);
  }
  .Carousel {
    overflow-y: hidden !important;
    width: 600px !important;
    .swiper-wrapper {
      height: 450px !important;
      .swiper-slide {
        width: 260px !important;
        height: 420px !important;
        .Poster_Image {
          height: 400px;
          width: 260px !important;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
  .Title {
    font-size: 25px !important;
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 630px) {
  .Carousel {
    overflow-y: hidden !important;
    width: 400px !important;
    .swiper-wrapper {
      height: 520px !important;
      .swiper-slide {
        width: 300px !important;
        height: 520px !important;
        .Poster_Image {
          height: 450px;
          width: 300px !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Cinema,
  .TVShow,
  .Documental,
  .Progress,
  .Distribution,
  .Partners {
    height: calc(100vh - 100px - 110px);
  }
  .Carousel {
    overflow-y: hidden !important;
    width: 300px !important;
    .swiper-wrapper {
      height: 450px !important;
      .swiper-slide {
        width: 260px !important;
        height: 420px !important;
        .Poster_Image {
          height: 400px;
          width: 260px !important;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }
  .Title {
    font-size: 25px !important;
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  .Carousel {
    .swiper-wrapper {
      height: 420px !important;
      .swiper-slide {
        width: 220px !important;
        height: 400px !important;
        .Poster_Image {
          height: 350px;
          width: 220px !important;
        }
      }
    }
  }
  .Title {
    font-size: 22px !important;
    margin-bottom: 0px !important;
  }
}
