.About {
  @apply flex items-center flex-col;
  text-align: start;
  height: calc(100vh - 90px - 150px);
  .Title {
    @apply uppercase;
    font-size: 30px;
    font-weight: 500;
    margin-top: 50px;
  }
  .AboutData {
    @apply flex justify-center flex-col;
    height: 100vh;
    // margin-top: 6vh;
    .About_body {
      @apply flex justify-center items-center text-start;
      gap: 30px;
      .About_item {
        img {
          width: 400px;
          height: auto;
          border-radius: 2px;
        }
        .AboutItemTitle,
        .AboutItemTitleRight {
          padding-top: 10px;
        }
        .AboutItemText,
        .AboutItemTextRight {
          font-size: 16px;
        }
        .AboutItemTitleRight,
        .AboutItemTextRight {
          text-align: end;
        }
      }
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 1200px),
  only screen and (min-width: 320px) and (max-width: 1000px) {
  .About {
    .Title {
      margin-bottom: 25px;
    }
    .AboutData {
      .About_text {
        @apply flex justify-center items-center;
        p {
          font-size: 17px;
          width: 463px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 350px;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 320px) and (max-height: 1080px),
  only screen and (min-width: 320px) and (max-width: 750px) {
  .About {
    .Title {
      margin-bottom: 25px;
      margin-top: 25px;
    }
    .AboutData {
      .About_text {
        p {
          font-size: 16px;
          width: 436px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 280px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 280px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 280px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 280px;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 320px) and (max-height: 950px) {
  .About {
    .Title {
      margin-bottom: 25px;
      margin-top: 20px;
    }
    .AboutData {
      margin-top: 10px;
      .About_text {
        p {
          font-size: 16px;
          width: 436px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 240px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 240px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 240px;
            font-size: 16px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 240px;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 320px) and (max-height: 840px) {
  .About {
    .Title {
      margin-bottom: 25px;
      margin-top: 20px;
    }
    .AboutData {
      .About_text {
        margin-bottom: 20px;
        p {
          font-size: 16px;
          width: 322px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 180px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 180px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 180px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 180px;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 320px) and (max-height: 750px) {
  .About {
    .Title {
      margin-top: -20px;
    }
    .AboutData {
      margin-top: 25px;
      .About_text {
        margin-bottom: 20px;
        p {
          font-size: 16px;
          width: 322px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 180px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 180px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 180px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 180px;
          }
        }
      }
    }
  }
}
@media only screen and (min-height: 320px) and (max-height: 685px) {
  .About {
    .Title {
      margin-top: -20px;
    }
    .AboutData {
      margin-top: 10px;
      .About_text {
        margin-bottom: 20px;
        p {
          font-size: 16px;
          width: 322px;
        }
      }
      .About_body {
        gap: 10px;

        .About_item {
          img {
            width: 160px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 160px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 160px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 160px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .About {
    .Title {
      margin-bottom: 25px;
    }
    .AboutData {
      .About_text {
        p {
          font-size: 16px;
          width: 436px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 280px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 280px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 280px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 280px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .About {
    .Title {
      margin-bottom: 25px;
      margin-top: 20px;
    }
    .AboutData {
      .About_text {
        p {
          font-size: 16px;
          width: 436px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 240px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 240px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 240px;
            font-size: 16px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 240px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .About {
    .Title {
      margin-bottom: 25px;
      margin-top: 20px;
    }
    .AboutData {
      .About_text {
        p {
          font-size: 16px;
          width: 322px;
        }
      }
      .About_body {
        gap: 20px;
        .About_item {
          img {
            width: 200px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 200px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 200px;
            font-size: 17px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 200px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 429px) {
  .About {
    .Title {
      margin-bottom: 5px;
      margin-top: 0px;
    }
    .AboutData {
      .About_text {
        p {
          font-size: 16px;
          width: 322px;
        }
      }
      .About_body {
        gap: 10px;
        .About_item {
          img {
            width: 160px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 160px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 160px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 160px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 389px) {
  .About {
    .Title {
      margin-top: -20px;
    }
    .AboutData {
      margin-top: 10px;
      .About_text {
        margin-bottom: 20px;
        p {
          font-size: 16px;
          width: 322px;
        }
      }
      .About_body {
        gap: 10px;

        .About_item {
          img {
            width: 150px;
          }
          .AboutItemTitle,
          .AboutItemTitleRight {
            width: 150px;
          }
          .AboutItemText,
          .AboutItemTextRight {
            width: 150px;
            font-size: 14px;
          }
          .AboutItemTitleRight,
          .AboutItemTextRight {
            width: 150px;
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 320px) and (max-width: 1400px),
//   only screen and (min-height: 320px) and (max-height: 1150px) {
//   .About {
//     .Title {
//       margin-bottom: 25px;
//     }
//     .About_body {
//       gap: 20px;
//       .About_item {
//         img {
//           width: 400px;
//         }
//         .About_text {
//           h1 {
//             font-size: 22px;
//             font-weight: 700;
//           }
//           p {
//             font-size: 14px;
//           }
//         }
//       }
//     }
//   }
// }
