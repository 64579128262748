.Modal_data {
  @apply flex flex-col;
  padding: 10px;
  gap: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Roboto Condensed", sans-serif;

  .Modal_sections {
    @apply flex;
    gap: 120px;
    .Modal_left_section {
      .trailer {
        width: 900px;
      }
      .ModalPhoto {
        height: 960px;
      }
      h3 {
        font-size: 40px;
        font-weight: 700;
        color: #3faac1;
        width: 900px;
      }
      p {
        width: 900px;
        font-size: 20px;
      }
    }
    .Modal_right_section {
      @apply flex flex-col;
      gap: 20px;
      .Modal_title {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 900;
        font-size: 80px;
      }
      .UnderTitle_data {
        @apply flex items-center;
        gap: 20px;
        .year {
          @apply flex justify-center items-center;
          gap: 5px;
          img {
            width: 25px;
            height: 25px;
          }
        }
      }
      .Director {
        @apply flex justify-start items-center;
        gap: 5px;
        img {
          width: 25px;
          height: 25px;
        }
      }
      .Producer {
        @apply flex justify-start items-center;
        gap: 5px;
        img {
          width: 25px;
          height: 25px;
        }
      }
      .Stars {
        @apply flex justify-start items-center;
        gap: 5px;
        img {
          width: 25px;
          height: 25px;
        }
      }
      .Festivals {
        @apply flex justify-start items-start;
        gap: 5px;
        img {
          width: 25px;
          height: 25px;
        }
        .Festivals_data {
          @apply flex flex-col;
        }
      }
      .Images {
        @apply flex items-center;
        gap: 25px;
        img {
          height: 200px;
          transition: 0.3s all;
          cursor: pointer;
        }
      }
    }
  }
}

.ModalImageLoader {
  @apply flex justify-center items-center;
  width: 400px;
  height: 200px;
  background-color: rgba(52, 52, 52, 0.29);

  .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: 0.9s;
    --uib-color: #ffffff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }

  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }

  .dot-spinner__dot::before {
    content: "";
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }

  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }

  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }

  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }

  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }

  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }

  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }

  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }

  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }

  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }

  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }

  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }

  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }

  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }

  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }

  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 2170px) {
  .Modal_data {
    .Modal_sections {
      gap: 80px;
      .Modal_left_section {
        .trailer {
          width: 850px;
        }
        h3 {
          width: 750px;
        }
        p {
          width: 750px;
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1980px) {
  .Modal_data {
    .Modal_sections {
      .Modal_left_section {
        .trailer {
          width: 800px;
        }
        h3 {
          width: 700px;
        }
        p {
          width: 700px;
        }
      }
      .Modal_right_section {
        .Modal_title {
          font-size: 75px;
        }
        .Images {
          img {
            height: 180px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1820px) {
  .Modal_data {
    .Modal_sections {
      .Modal_right_section {
        .Images {
          @apply flex-wrap;
          img {
            height: 180px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1600px) {
  .Modal_data {
    .Modal_sections {
      .Modal_left_section {
        .trailer {
          width: 800px;
        }
        h3 {
          width: 700px;
        }
        p {
          width: 700px;
        }
      }
      .Modal_right_section {
        .Modal_title {
          font-size: 70px;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 1540px) {
  .Modal_data {
    .Modal_sections {
      .Modal_left_section {
        .ModalPhoto {
          height: 700px;
          width: 525px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1480px) {
  .Modal_data {
    .Modal_sections {
      gap: 70px;
      .Modal_left_section {
        .trailer {
          width: 700px;
        }
      }
      .Modal_right_section {
        .Modal_title {
          font-size: 65px;
        }
        .UnderTitle_data {
          @apply flex-wrap;
        }
        .Images {
          @apply flex flex-nowrap;
          overflow-x: auto !important;
          overflow-y: hidden;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1330px) {
  .Modal_data {
    .Modal_sections {
      .Modal_left_section {
        .ModalPhoto {
          height: 600px;
          width: 450px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1300px) {
  .Modal_data {
    .Modal_sections {
      gap: 60px;
      .Modal_right_section {
        .Modal_title {
          font-size: 60px;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 1200px) {
  .Modal_data {
    @apply flex flex-col;
    padding: 10px;
    gap: 40px;

    .Modal_sections {
      @apply flex flex-col;
      gap: 40px;
      .Modal_left_section {
        .trailer {
          width: auto !important;
        }
        h3 {
          font-size: 40px;
          width: auto !important;
        }
        p {
          width: auto !important;
          font-size: 18px;
        }
      }
      .Modal_right_section {
        @apply flex flex-col;
        gap: 20px;
        .Modal_title {
          font-weight: 900;
          font-size: 70px;
        }
        .UnderTitle_data {
          @apply flex items-center;
          gap: 20px;
          .PG {
            @apply flex justify-center items-center;
            width: 60px;
            height: 30px;
            border-radius: 2px;
            background-color: white;
            color: black;
            font-weight: 900;
          }
          .Size {
            @apply flex justify-center items-center;
            width: 60px;
            height: 30px;
            border-radius: 2px;
            background-color: none;
            border: 3px solid white;
            color: white;
            font-weight: 900;
          }
          .year {
            @apply flex justify-center items-center;
            gap: 5px;
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
        .Director {
          @apply flex justify-start items-center;
          gap: 5px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .Producer {
          @apply flex justify-start items-center;
          gap: 5px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .Stars {
          @apply flex justify-start items-center;
          gap: 5px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .Festivals {
          @apply flex justify-start items-start;
          gap: 5px;
          img {
            width: 25px;
            height: 25px;
          }
          .Festivals_data {
            @apply flex flex-col;
          }
        }
        .Images {
          @apply flex items-center;
          gap: 25px;
          img {
            height: 200px;
            transition: 0.3s all;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 550px) {
  .Modal_data {
    .Modal_sections {
      .Modal_right_section {
        .Modal_title {
          font-size: 60px;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Modal_data {
    .Modal_sections {
      .Modal_left_section {
        .ModalPhoto {
          height: 520px;
          width: 400px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  .Modal_data {
    .Modal_sections {
      .Modal_right_section {
        .Modal_title {
          font-size: 50px;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .Modal_data {
    .Modal_sections {
      .Modal_left_section {
        .ModalPhoto {
          height: 420px;
          width: 320px;
        }
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 380px) {
  .Modal_data {
    .Modal_sections {
      .Modal_right_section {
        .Modal_title {
          font-size: 40px;
        }
      }
    }
  }
}
