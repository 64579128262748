.Partners {
  // .PatnresSwiper {
  //   width: 500px;
  //   height: 800px;
  // }
  .PartnersData {
    @apply flex justify-center items-center;
    .Partner_item {
      width: 500px;
      height: 800px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1200px),
  only screen and (min-height: 320px) and (max-height: 1150px) {
  .Partners {
    .PartnersData {
      height: 800px;
      .Partner_item {
        width: 400px;
        height: 600px;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px),
  only screen and (min-height: 320px) and (max-height: 950px) {
  .Partners {
    .PartnersData {
      .Partner_item {
        width: 300px;
        height: 500px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px),
  only screen and (min-height: 320px) and (max-height: 800px) {
  .Partners {
    .PartnersData {
      .Partner_item {
        width: 260px;
        height: 420px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 400px),
  only screen and (min-height: 320px) and (max-height: 700px) {
  .Partners {
    .PartnersData {
      height: 400px;
      .Partner_item {
        width: 260px;
        height: 420px;
      }
    }
  }
}
