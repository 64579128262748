.Nav_body {
  @apply flex justify-center items-start flex-col text-start;
  position: absolute;
  margin-left: 10px;
  left: 0;
  width: 9vw;
  z-index: 2;
  button {
    letter-spacing: 0;
    transition: 0.5s all;
    font-size: 14px;
    text-transform: uppercase;

    &:active {
      transform: translateY(10px);
    }

    &:hover,
    &:active {
      letter-spacing: 1px;
    }

    &:after,
    &:before {
      backface-visibility: hidden;
      border: 1px solid rgba(#fff, 0);
      bottom: 0px;
      content: " ";
      display: block;
      margin: 0 auto;
      position: relative;
      transition: all 280ms ease-in-out;
      width: 0;
    }

    &:hover:after,
    &:hover:before {
      backface-visibility: hidden;
      border-color: #fff;
      transition: width 350ms ease-in-out;
      width: 100%;
    }

    &:hover:before {
      bottom: auto;
      top: 0;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1100px) {
  .Nav_body {
    display: none !important;
  }
}
