.Footer {
  @apply flex justify-center items-center;
  height: 90px;
  width: 100vw;
  z-index: 2000;
  gap: 80px;
  background-color: rgba($color: #02091c, $alpha: 0.35) !important;
  transition: 0.5s all;
  position: fixed;
  left: 0;
  bottom: 0;
  opacity: 1;

  button {
    width: 50px;
    height: 50px;
    transition: 0.5s all;
    img {
      width: 50px;
      height: 50px;
      transition: 0.5s all;
    }
    &:hover img {
      filter: brightness(0) invert(1);
    }
    &:active {
      transform: translateY(10px);
    }
  }
  .menu,
  .menuCont,
  .menuSoc {
    button {
      text-align: start;
    }
  }
  .menu {
    @apply flex justify-center items-center flex-col;
    text-align: start;
    position: fixed !important;
    margin-bottom: 320px;
    border-radius: 2px;
    padding: 5px;
    z-index: 2000;
    padding-top: 10px;
    min-height: 220px;
    min-width: 115px;
    background-color: rgba($color: #02091c, $alpha: 0.5) !important;
    backdrop-filter: blur(5px);

    button {
      height: 30px;
      width: 105px;
    }
  }
  .menuCont {
    @apply flex justify-center items-center flex-col;
    position: fixed !important;
    margin-bottom: 215px;
    border-radius: 2px;
    padding: 5px;
    z-index: 2000;
    min-height: 120px;
    min-width: 130px;
    background-color: rgba($color: #02091c, $alpha: 0.5) !important;
    backdrop-filter: blur(5px);

    a {
      height: 30px;
      width: 120px;
    }
  }
  .menuSoc {
    @apply flex justify-center items-center flex-col;
    position: fixed !important;
    margin-bottom: 215px;
    border-radius: 2px;
    padding: 5px;
    z-index: 2000;
    min-height: 120px;
    min-width: 115px;
    background-color: rgba($color: #02091c, $alpha: 0.5) !important;
    backdrop-filter: blur(5px);

    a {
      height: 30px;
      width: 105px;
    }
  }
}

.opEnter {
  opacity: 1 !important;
}
.opExit {
  opacity: 0 !important;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Footer {
    button {
      width: 25px;
      height: 25px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  .Footer {
    height: 50px;
    margin-top: calc(100vh - 50px);
    button {
      width: 25px;
      height: 25px;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .menu {
      @apply flex justify-center items-center flex-col;
      margin-bottom: 280px;
    }
    .menuCont {
      @apply flex justify-center items-center flex-col;
      margin-bottom: 175px;
    }
    .menuSoc {
      @apply flex justify-center items-center flex-col;
      margin-bottom: 175px;
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 750px) {
  .Footer {
    height: 50px;
    margin-top: calc(100vh - 50px);
    button {
      width: 25px;
      height: 25px;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .menu {
      @apply flex justify-center items-center flex-col;
      margin-bottom: 280px;
    }
  }
}
