@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir_roman_12.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

.Home,
.HomeSmall {
  @apply flex items-center justify-center;
  padding: 5px;
  height: 100vh;
  gap: 5vw;
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 0);
  transition: 0.5s all;
  overflow: hidden;
  font-family: "Roboto Condensed", sans-serif;

  .Home_title {
    @apply flex justify-center items-center flex-col text-center;
    width: 100vw;
    z-index: 1;
    opacity: 1;
    transition: 0.5s all;
    animation: onEnter 1s 1;
    .LogoSmall {
      width: 180px;
      height: auto;
    }
  }
  .Home_text {
    width: 600px;
    text-align: center;
  }
}

.HomeSmall {
  height: 150px;
}

.opEnter {
  background-color: rgba($color: #02091c, $alpha: 0.35) !important;
}
.opExit {
  background-color: rgba($color: #02091c, $alpha: 0) !important;
}

@keyframes onEnter {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .Home,
  .HomeSmall {
    .Home_title {
      margin-top: 20px;
      h1 {
        font-size: 20px;
      }
      .LogoSmall {
        width: 80px;
        height: auto;
      }
    }
    .Home_text {
      width: 420px;
      font-size: 17px;
    }
  }
  .HomeSmall {
    height: 80px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Home,
  .HomeSmall {
    .Home_title {
      margin-top: 20px;
      h1 {
        font-size: 20px;
      }
    }
    .Home_text {
      width: 320px;
      font-size: 16px;
    }
  }
}
@media only screen and (min-height: 320px) and (max-height: 750px) {
  .Home,
  .HomeSmall {
    .Home_title {
      margin-top: 20px;
      h1 {
        font-size: 20px;
      }
      .LogoSmall {
        width: 80px;
        height: auto;
      }
    }
  }
  .HomeSmall {
    height: 80px;
  }
}
