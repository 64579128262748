.Logo {
  width: 240px;
  height: auto;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir_roman_12.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

.App_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

@keyframes openPage {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@keyframes backgroundAnimation {
  0% {
    border-bottom-color: #3faac1;
  }
  25% {
    border-bottom-color: #31bede;
  }
  50% {
    border-bottom-color: #0bc3ec;
  }
  75% {
    border-bottom-color: #8bd7e8;
  }
  100% {
    border-bottom-color: #3faac1;
  }
}

.Loader {
  @apply flex justify-center items-center;
  z-index: 10000;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgb(2, 12, 17);
  // -webkit-animation: openPage 0.5s;
  // animation: openPage 0.5s;
  // -webkit-animation-delay: 3.5s;
  // animation-delay: 3.5s;

  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 40px;
    position: absolute;
    z-index: 10000;
    font-family: "avenir", sans-serif;
  }
  h2 {
    font-weight: 200;
    letter-spacing: 2px;
    font-size: 20px;
    position: absolute;
    top: calc(50% + 20px);
    z-index: 10000;
    font-family: "avenir", sans-serif;
  }
  .triangle {
    position: relative;
    width: 0;
    height: 0;
    border: 80px solid transparent;
    border-top: 0;
    border-bottom: 140px solid red;
    z-index: 2000;
    transform: rotate(-8deg);
    animation: backgroundAnimation 1s infinite;
  }
  .triangle::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -33%);
    border-top: 0;
    border: 75px solid transparent;
    border-bottom: 130px solid rgb(2, 12, 17);
    z-index: 100;
  }
}

.App {
  overflow-x: hidden !important;
}

.s1,
.s2,
.s3,
.s4,
.s5,
.s6,
.s7,
.s8 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1 {
  font-size: 120px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.Header {
  display: none;
}

.Navigation {
  position: absolute;
  width: 100px;
  z-index: 2000;
  top: 43.5%;
  left: 10px;
}

.BottomNav {
  position: absolute;
}

.Language {
  position: fixed;
  z-index: 2000;
  top: 50px;
  right: 50px;
  transition: 0.5s all;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: translateY(15px);
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.Modal {
  border-radius: 2px;
  width: 99.5%;
  height: calc(100% - 100px - 150px);
  position: fixed;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: rgba($color: #021a20, $alpha: 0.99);
  overflow-y: auto;
  overflow-x: hidden;
  .Modal_header {
    @apply flex justify-end;
    position: fixed;
    right: 0;
    padding: 10px;
    button {
      width: 25px;
      height: 25px;
      transition: 0.5s all;

      &:active {
        transform: translateY(15px);
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .Modal_body {
    margin-top: 50px;
  }
}

@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (min-width: 320px) and (max-width: 1100px) {
  .Language {
    top: 30px;
    right: 35px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Language {
    top: 30px;
    right: 35px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 750px) {
  .Language {
    top: 30px;
    right: 35px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

@media only screen and (min-height: 320px) and (max-height: 1100px) {
  .Modal {
    top: 52.7%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 1000px) {
  .Modal {
    top: 53%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 900px) {
  .Modal {
    top: 53.4%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 800px) {
  .Modal {
    top: 53.9%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Modal {
    height: calc(100% - 100px - 110px);
    top: 50.85%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 450px) {
  .Modal {
    height: calc(100% - 70px - 110px);
    top: 52%;
  }
}

@media only screen and (min-height: 320px) and (max-height: 900px) and (min-width: 320px) and (max-width: 500px) {
  .Modal {
    top: 51.2%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 830px) and (min-width: 320px) and (max-width: 500px) {
  .Modal {
    top: 51.5%;
  }
}

@media only screen and (min-height: 320px) and (max-height: 1100px) and (min-width: 320px) and (max-width: 450px) {
  .Modal {
    top: 52.5%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 900px) and (min-width: 320px) and (max-width: 450px) {
  .Modal {
    top: 53%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 800px) and (min-width: 320px) and (max-width: 450px) {
  .Modal {
    top: 53.5%;
  }
}
@media only screen and (min-height: 320px) and (max-height: 750px) {
  .Modal {
    height: calc(100% - 50px - 80px);
    top: 52%;
  }
}

.Background {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(
    315deg,
    rgba(101, 0, 94, 1) 3%,
    rgba(60, 132, 206, 1) 38%,
    rgba(48, 238, 226, 1) 68%,
    rgb(47, 2, 105) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 15%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}
