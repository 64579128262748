.Layout {
  z-index: 1;
  .Layout_body {
    @apply flex justify-center items-center;
    padding: 10px 5px;
    width: 100vw;
    padding-top: 150px;
    padding-bottom: 90px;
    height: 100vh;
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Layout {
    .Layout_body {
      padding-top: 80px;
      height: 100vh;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 450px) {
  .Layout {
    .Layout_body {
      padding-bottom: 50px;
      height: 100vh;
    }
  }
}
